import axios from "axios";

export default {
    async getLists(userID: string) {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/todo-list/todo/getLists/${userID}`, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    async getTodos(listID: string) {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/todo-list/todo/getTodosListApp/${listID}`, {
            //const response = await axios.get(`http://192.168.11.141/firegui_crm_h2/todo-list/todo/getTodosListApp/${listID}`, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * ! Toggle todo status
     */
    async toggleTodo(todoId, todoStatus) {
        const response = await axios.post(`${process.env.VUE_APP_BASE_URL}todo-list/todo/toggleDoneTodo/${todoId}/${todoStatus}`, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },

    /**
     * ! Get current list categories
     */
    async getCategories(listId: string) {
        const data = new FormData();
        data.append("where[]", `fi_todo_list_categories_list_id = '${listId}'`);
        data.append("orderby", "fi_todo_list_categories_name");
        data.append("orderdir", "ASC");

        const response = await axios.post("rest/v1/search/fi_todo_list_categories", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
    /**
     * ! Get current list categories
     */
    async createTodo(data) {
        //const response = await axios.post(`http://192.168.11.124/firegui_crm_h2/rest/v1/create/fi_todo`, data, {
        const response = await axios.post("rest/v1/create/fi_todo", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response;
    },
};
